/* 110% to 133%  */
@media (min-width:1440px) and (max-width:1900px) {
    .rcs_signup_to_input_field .input-group span {
        font-size: 12px!important;
        font-weight: 500!important;
        width: 80px!important;
    }
    .custum_contain {
        max-width: 1450px;
    }
    .get_in {
        padding: 29px 55px;
    }
    .rcs_collection1_box_img.rcs_collection_height {
        height: auto!important;
    }
    .inner_slid h1 {
        font-size: 40px;
    }
    .inner_slid h5 {
        font-size: 20px;
    }
    .lab_one {
        height: 464px;
    }
    .inner_slid {
        padding-left: 5%;
        padding-top: 131px;
    }
    .lab_two {
        height: 464px;
    }
    .dim img {
        width: 414px;
        height: 343px;
    }
    .title_one {
        font-size: 30px;
    }
    .reflect h2 {
        font-size: 40px;
    }
   
    .rcs_footer_content h5 {
        font-size: 20px!important;
    }
    .get_in button.settings.setborde.btn.btn-primary {
        margin-left: 0;
        margin-top: 0px;
    }
    .reflect {
        padding-top: 13px;
    }
    .reflect p {
        font-size: 14px;
    }
    .reflect h4 {
        font-size: 22px;
    }
    .rcs_exp_collection_main img {
        width: 100%;
    }
    .rcs_custom_home_container.container {
        max-width:1452px!important;
    }
    .get_in {
        padding: 70px 35px;
        height: 90%;
    }
    .back_gry {
        background: #f7f7f7;
        padding-left: 20px;
        padding-bottom: 0px;
        padding-top: 0;
        height: 100%; 
        display: grid;
        align-items: center;
    }
    .about_lover_text p {
        font-size: 14px;
    }
    .about_lover_text h5 {
        font-size: 30px;
        
    }
    .info_icons span {
        font-size: 14px;
    }
    .rcs_collection1_box_btn {
        bottom:6%!important;
    }
    .rcs_signup_to_input_field .input-group input {
        border-radius: 0;
        height: 50px;
        border-color: #0F75BC;
        border: 2px solid #0F75BC;
        box-shadow: none;
        width: 200px;
    }
}

/* normal desktop screen */
@media only screen and (min-width: 1200px){
    
}
/* @media (min-width:10110) and (max-width:1270) {
    .imglog {
        width: 210px;                                    
    }


    } */

/* 150% */
@media (min-width:1280px) and (max-width:1439px) {
/* @media (min-width: 1200px) and (max-width: 1470px) */
.rcs_signup_to_input_field .input-group span {
    font-size: 12px!important;
    font-weight: 500!important;
    width: 80px!important;
}
.rcs_signup_to_input_field .input-group input {
    border-radius: 0;
    height: 50px;
    border-color: #0F75BC;
    border: 2px solid #0F75BC;
    box-shadow: none;
    width: 179px!important;
    height: 50px!important;
}
.inner_slid h1 {
    font-size: 40px;
}
.inner_slid h5 {
    font-size: 20px;
}
.lab_one {
    height: 464px;
}
.inner_slid {
    padding-left: 5%;
    padding-top: 131px;
}
.lab_two {
    height: 464px;
}
.dim img {
    width: 414px;
    height: 343px;
}
.title_one {
    font-size: 30px;
}
.reflect h2 {
    font-size: 40px;
}

.rcs_footer_content h5 {
    font-size: 20px!important;
}
.get_in button.settings.setborde.btn.btn-primary {
	margin-left: 10px;
	margin-top: 0;
}
.reflect {
    padding-top: 13px;
}
.reflect p {
    font-size: 14px;
}
.reflect h4 {
    font-size: 22px;
}
.rcs_exp_collection_main img {
    width: 100%;
}
.rcs_custom_home_container.container {
    max-width: 1205px!important;
}
.get_in {
    padding: 29px 55px;
}
.back_gry {
    padding-top: 20px;
    background: #f7f7f7;
    padding-left: 14px;
    padding-bottom: 0px;
    height: 426px;
}
.about_lover_text p {
    font-size: 14px;
}
.about_lover_text h5 {
    font-size: 30px;
    
}
.info_icons span {
    font-size: 14px;
}

}

@media (max-width: 991px) {



}
@media (min-width: 991px) {

}
@media(max-width:991px){

}
@media(min-width:991px){

}

/*\\\\\\\\\\\\\\\\\IPAD VIEW */
@media (min-width: 992px) and (max-width: 1024px) { 
    .lab_one {
        height: 549px!important;
        background-position: center;
    }   
    .inner_slid {
        padding-left: 5%;
        padding-top: 171px;
    }
    .lab_two {
        height: 549px!important;
        background-position: center;
    } 
    .two_buttons span {
        display: none;
    }
    .two_buttons .setborde.btn.btn-primary {
        margin-top: 10px;
    }
    .abt_lov_img {
        padding-right: 0!important;
    }
    .rcs_signup_to_input_field .input-group span {
        width: 100%!important;
    }
    .abtout {
        padding-top: 1px!important;
    }
    .back_gry {
        padding-top: 8px;
        background: #f7f7f7;
        padding-left: 9px;
        padding-bottom: 0px;
        height: 340px;
    }
    .get_in {
        /* padding: 80px 48px; */
        padding: 14px 31px;
    }
    .title_one {
        font-size: 25px;
    }
    .info_icons {
        padding-top: 7px;
    }
    button.settings {
        width: 287px;
        height: 40px;
        font-size: 15px;
    }
}
@media (min-width:1024px) and (max-width:1199px) { 
    ul.infos {
        margin-top: 23px;
    }
    .cal a {
        font-size: 13px;
    }
} 
@media (min-width:992px) and (max-width:1279px) { 
   
} 

/* ipad  */
@media (min-width:820px) and (max-width:1180px) { 


}

@media (min-width:768px) and (max-width:991px) { 
    .middelnav {
        padding: 10px 16px;
    }
    .get_In_touch {
        margin-bottom: 50px;
    }
    .gs_footer_content .Mui-expanded .MuiAccordionSummary-gutters p {
        color: white!important;
    }
    .soutyh_img img {
        width: 100%;
    }
    .nop {
        padding-left: 15px;
    }
    .get_in {
        padding: 36px 30px;
        border: 1px solid #0F75BC;
        border-right: 1px solid #0F75BC;
    }
    .title_one {
        font-size: 29px;
    }
    section.rcs_ring_style_section {
        padding-top: 50px!important;
        padding-bottom: 50px!important;
    }
    button.settings {
        width: 244px;
        height: 40px;
        font-size: 15px;
    }
    .reflect h2 {
        font-size: 45px;
    }
    .abt_lov_img {
        padding-right: 0!important;
    }
    .dream {
        display: block;
    }
    .inner_slid {
        padding-left: 8%;
        padding-top: 74px;
    }
    .lab_one {
        height: 265px!important;
        background-position: center;
    }
    .lab_two {
        height: 265px!important;
        background-position: center;
    }
    .inner_slid h1 {
        font-size: 21px;
    }
    .inner_slid h5 {
        font-size: 14px;
        padding-bottom: 16px;
    }
    .Baner_button.btn.btn-primary {
        width: 181px;
        height: 43px;
        font-size: 15px;
    }
    .dim img {
        width: 100%;
    }
    .abt_lov_img img {
        width: 100%;
    }
   
    .get_in button.settings.setborde.btn.btn-primary {
        margin-left: 10px;
        /* margin-top: 10px; */
    }
    .design button.settings.setborde.btn.btn-primary {
        margin-top: 10px;
    }
    .nospave {
        padding-right: 15px;
    }
    .back_gry {
        padding: 15px;
        margin-bottom: 15px;
        height: 385px;
    }
    .dream {
        display: flex!important;
    }
    .reflect {
        padding-top: 0;
    }
    .reflect h2 {
        font-size: 50px;
    }
    
    .abt_lov_img {
        padding-right: 0;
    } 
    .two_buttons span {
        display: none;
    }
    .shop_diamond_shapes .slick-next {
        right: 0px;
        z-index: 9;
        color: #000 !important;
    }

    .shop_diamond_shapes .slick-prev {
        left: 0px;
        z-index: 9;
        color: #000 !important;
    }

    .abtout {
        padding-top: 25px;
    }
    .herosection .slick-next {
        right: 1%;
    }
    .herosection .slick-prev {
        left: 3px;
        opacity: 1;
        z-index: 1;
    }

    .rcs_customer_say_inner .slick-prev {
        left: -120px;
    }
    .parallax-herosection .content,
    .parallax-herosection .react-parallax:nth-child(even) .content {
        right: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .react-parallax-bgimage {
        height: 70vh !important;
    }
    
    .react-parallax-content > div {
        height: 50vh !important;
    }
}
    @media (min-width:576px) and (max-width:761px) { 

    }
    @media (min-width: 576px){
    }
    /* \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\mobile samll view\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */
    @media (min-width:321px) and (max-width:480px) { 

    } 
    @media (min-width:530px) and (max-width:767px) { 
        
    }
    @media (min-width:320px) and (max-width:767px) { 
        /* .rcs_right_side.rcs_right_side_mobile {
        padding: 0 27px!important;
    } */
    

    .mg_menu_right_side_area {
        width: 27% !important;
    }

    .rcs_right_side_mobile ul li {
        width: auto!important;
        margin: 0 2px!important;
    }
    .about_lover_text {
        text-align: center;
    }
    section.rcs_mobile_footer_section {
        padding-top: 0!important;
    }
    .get_in h2.title_one {
        text-align: center;
    }
    .dream {
        display: block;
    }
    .inner_slid {
        padding-left: 15px;
        padding-top: 30px;
    }
    .lab_one {
        height: 265px!important;
        background-position: center;
    }
    .lab_two {
        height: 265px!important;
        background-position: center;
    }
    .inner_slid h1 {
        font-size: 24px;
    }
    .inner_slid h5 {
        font-size: 14px;
        padding-bottom: 10px;
        line-height: 1.6;
    }
    .Baner_button.btn.btn-primary {
        width: 60%;
        height: 43px;
        font-size: 15px;
    }
    .dim img {
        width: 100%;
    }
    .abt_lov_img img {
        width: 100%;
    }
    .soutyh_img img {
        width: 100%;
    }
    /* .get_in button.settings.setborde.btn.btn-primary {
        margin-left: 0;
    } */
    .nospave {
        padding-right: unset;
    }
    .two_buttons span {
        display: none;
    }
    .design button.settings.setborde.btn.btn-primary {
        margin-top: 10px;
    }
}
@media (min-width:320px) and (max-width:600px) {
      
    
    
}


@media (min-width:688px) and (max-width:768px) { 
    
    
} 
@media (min-width:601px) and (max-width:767px) {
    
}

@media only screen and (max-width: 600px) {
    .title_one {
        font-size: 24px;
    }

    .reflect {
        font-size: 24px;
        text-align: center;
    }

    .reflect h2 {
        font-size: 45px;
    }

    .two_buttons span {
        display: none;
    }

    button.settings {
        width: 100%;
        margin-bottom: 15px;
    }

    section.rcs_ring_style_section {
        padding: 50px 0;
    }

    .slick-slider.rcs_customer_say_inner.rcs_shop_diamond_slider.slick-initialized button.slick-arrow.slick-prev, .slick-slider.rcs_customer_say_inner.rcs_shop_diamond_slider.slick-initialized button.slick-arrow.slick-next {
        width: 50px !important;
    }

    .rcs_shop_by_diamond_box_text h3 {
        font-size: 20px;
    }

    .abt_lov_img {
        padding-right: 0px !important;
    }

    .abtout {
        padding-top: 35px;
    }

    .about_lover_text h5 {
        font-size: 24px;
    }

    .our_promice {
        margin-top: 50px;
    }

    .our_promice .return img{
        width: 20%;
    }

    .get_In_touch {
        margin: 50px 0;
    }

    .get_in {
        border: 1px solid #0F75BC;
    }

    .back_gry {
        height: 700px;
        width: 100%;
    }
    
    .get_in {
        border: 1px solid #0F75BC;
    }

    .slick-next {
        right: 25px;
    }

    .slick-prev {
        left: 15px;
        z-index: 9;
    }

    .inner_slid {
        padding-left: 0;
        padding-top: 15px;
        text-align: center;
    }

    .middelnav {
        padding: 15px 0;
    }

    .inner_slid {
        padding-top: 55px;
    }

    .shop.diamondss {
        padding-top: 30px;
    }

    .diamd {
        padding-bottom: 30px;
    }

    .title_one {
        font-size: 24px;
        margin: 0;
    }

    .dia_shape h5 {
        margin-bottom: 0;
    }

    .back_gry {
        padding-right: 15px;
        padding-bottom: 15px;
        height: 100%;
    }

    .get_In_touch .nop {
        padding-left: 15px !important;
    }

    .rcs_collection_section1 .slick-slide img {
        width: 100% !important;
    }

    .shop_diamond_shapes .slick-next {
        right: 0px;
        z-index: 9;
        color: #000 !important;
    }
    
    .shop_diamond_shapes .slick-prev {
        left: 0px;
        z-index: 9;
        color: #000 !important;
    }

    .loverdiam_logo img {
        width: 180px;
    }
}