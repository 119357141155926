/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500&family=Josefin+Sans&family=Libre+Baskerville&family=Nunito&family=Playfair+Display&family=Poppins:wght@400;500;700&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500&family=Josefin+Sans&family=Nunito&family=Playfair+Display&family=Poppins:wght@400;500;700&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap');

body{
    color: #000;
}
.custum_contain{
    max-width:1542px;
}
html,body {
    overflow-x: hidden;
}
/*  */



/* navagation */
.middelnav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 0;
	transition: 0.1s all ease-in-out;
}

.middelnav .info_conss,
.middelnav .loverdiam_logo,
.middelnav .mg_menu_right_side_area {
    width: 33.333%;
}

.middelnav .row {
    display: none;
}


.cal a svg,
.cal a img {
    vertical-align: sub;
}

.cal a {
    color: #000;
    font-family: Josefin Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
}

.cal a span {
    padding: 5px;
}

.infos {
    display: flex;
    align-items: center;
    column-gap: 30px;
}

nav#rcs_menu_header_top {
    display: none;
}
/*++++++++++++++Bannerslider++++++++++++++++++++*/
.herosection {
    padding-top: 0 !important;
}

.herosection .slick-dots {
    position: absolute;
    bottom: 1px;
}
.lab_one{
background-image:url('../../images/home/bannerone.png');
background-repeat: no-repeat;
background-size: cover;
height:700px;
}
.lab_two{
    background-image:url('../../images/home/bannertwo.png');
    background-repeat: no-repeat;
    background-size: cover;
    height:700px;
    }
    .inner_slid h1,.inner_slid h5 {
        font-family: 'Libre Baskerville', serif!important;
    }
    .inner_slid {
        /* padding-left: 13%; */
        padding-top: 253px;
    }
    .inner_slid h1 {
        font-size: 60px;
    }
    .inner_slid h5 {
        font-size: 30px;
        padding-bottom: 30px;
    }
    .Baner_button.btn.btn-primary {
        width: 273px;
        height: 48px;
        background-color: white;
        color: #0f75bc;
        border-color: white;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 18px;
        font-weight: 400;
        border-radius: 1px;
    }
    .herosection .slick-dots li.slick-active button:before {
        content: "";
        background: url('../../images/home/rectagelblue.png');
        opacity: 1;
        background-repeat: no-repeat;
    }
    .herosection .slick-dots li button:before {
        content: "";
        background: url('../../images/home/rectagelwhite.png');
        opacity: 1;
        background-repeat: no-repeat;
    }

    /*+++++++++++++++RingStyle+++++++++++++++++++++*/
    .shop_diamond_shapes {
        background-color: #F8F8F8;
        text-align: center;
    }
    .dia_shape {
        display: flex;
        justify-content: center;
      }
      .dia_shape a {
        text-align: center;
        color: black;
        font-weight: 300;
    }
    .dia_shape h5{
        font-size: 16px;
        padding-top: 10px;  
    }
    .title_one {
        font-size: 36px;
    }
    .shop.diamondss {
        padding-top: 50px;
    }
    .diamd {
        padding-bottom: 50px;
        padding-top: 9px;
    }
    /*++++++++++++++RING Buildr Home+++++++++++++++++*/
    .two_buttons svg {
        margin-left: 20px;
    }
    .Create_Your_Dream {
        border-bottom: 1px solid #D9D9D9;
    }
    .reflect h4 {
        font-size: 24px;
    }
    .reflect h2 {
        font-size: 60px;
    }
    .reflect p {
        font-size: 16px;
    }
    .dream {
        display: flex;
        justify-content: center;
        justify-content: space-evenly;
    }    
    .two_buttons button {
        background-color: #0F75BC;
    }
    button.settings {
        width: 287px;
        height: 50px;
        font-size: 18px;
        border-color: #0F75BC;
        line-height: 18px;
        border-radius: 1px;
        text-transform: capitalize;
    }
    .setborde.btn.btn-primary {
        background-color: transparent;
        color: #0F75BC;
    }
    .two_buttons span {
        opacity: 0.3;
        padding-left: 20px;
        font-size: 20px;
        padding-right: 25px;
        font-weight: 600;
        vertical-align: middle;
    }
    .shop_expore {
        width: 134px;
        height: 36px;
        color: white!important;
        background-color: transparent!important;
        border-color: white!important;
        border-radius: 1px;
        box-shadow: none!important;
    }
    .shop_expore a {
        color: white;
        font-size: 18px;
        text-align: center;
    }
    .about_lover_text h5 {
        font-size: 36px;
        line-height: 1.5;
    }
    .about_lover_text p {
        font-size: 16px;
        font-weight: 300;
        /* color: black; */
    }
    .about_lover_text p {
        font-size: 16px;
        font-weight: 300;
        /* color: black; */
        /* line-height: 32px; */
        line-height: 1.6;
        opacity: 0.8;
    }
    .know.btn.btn-primary {
        border: 1px solid #0f75bc;
        background: #FFF;
        color: #0f75bc;
        width: 192px;
        height: 50px;
        border-radius: 1px;
        font-size: 18px;
        line-height: 2.3;
        /* font-style: normal; */
        font-weight: 400;
    }
    .abtout {
        padding-top: 84px;
    }
    .about_lover_diamod {
        padding-top: 100px;
    }
    /* VIRTUAL APPOINMENT     our promices to you */
    .our_promice {
        background-color: #f3f8fc;
        /* height: 398px; */
        margin-top: 100px;
        padding-bottom: 30px;
    }
    .ourhed {
        text-align: center;
        padding-top: 40px;
        padding-bottom:40px;
    }
    .box {
        text-align: center;
    }
    .Return_texts {
        padding-top: 30px;
    }
    .Return_texts h4 {
        font-weight: 400;
        font-size: 24px;
    }
    .Return_texts p {
        font-size: 14px;
    }
    .get_In_touch {
        margin-top: 100px;
        margin-bottom: 100px;
    }
    .info_icons li a {
        color: #000;
        display: flex;
        padding-bottom: 10px;
    }
    .info_icons span {
        padding-left: 20px;
        font-size: 18px;
    }
    .back_gry {
        background: #f7f7f7;
        /* padding: 20px 33px 0px  */
    }
    .get_in {
        padding: 94px 48px;
        border: 1px solid #0F75BC;
        border-right: none;
    }
    .nospave {
        padding-right: 0;
    }
    .nop {
        padding-left: 0;
    }
    .back_gry {
        padding-top: 14px;
        background: #f7f7f7;
        padding-left: 14px;
        padding-bottom: 0px;
        height: 463px;
    }
    .get_in {
        /* padding: 80px 48px; */
        padding: 67px 55px;
        border: 1px solid #0F75BC;
        border-right: none;
    }
    .get_in button.settings.setborde.btn.btn-primary {
        margin-left: 20px;
    }
    .info_icons {
        padding-top: 25px;
    }
    button.setbg:hover,button.setbg:active,button.setbg :visited,button.setbg:target,button.setbg:focus {
        box-shadow: none!important;
        color: #fff!important;
        background-color: #0F75BC!important;
        border-color: #0F75BC!important;
    }
    button.setborde:hover,button.setborde:active,button.setborde:visited,button.setborde:target,button.setborde:focus {
        box-shadow: none!important;
        border-color: #0F75BC!important;
        background-color: transparent!important;
        color: #0F75BC!important;
    }
    .Baner_button:hover,.Baner_button:active,.Baner_button:visited,.Baner_button:target,.Baner_button:focus {
        box-shadow: none!important;
        border-color: #0F75BC!important;
        background-color: transparent!important;
        color: #0F75BC!important;
    }
    .Baner_button:hover,.Baner_button:active,.Baner_button:visited,.Baner_button:target,.Baner_button:focus {
        box-shadow: none!important;
        border-color: #0F75BC!important;
        background-color: transparent!important;
        color: #0F75BC!important;
    }

.rcs_logo_head.rcs_sticky-top.sticky-top .middelnav {
    padding: 15px 0;
}

.subtitle {
    margin: 10px 20px 0 0;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #aaaaaa;
}

@media only screen and (max-width: 992px) {
    .custum_contain {
        max-width: 100%;
    }

    .loverdiam_logo img {
        width: 100%;
    }

    .rcs_logo_head {
        position: relative;
    }

    .rcs_mobile_header_menu {
        top: 50% !important;
        right: 15px;
        transform: translateY(-50%);
    }
}

@media only screen and (max-width: 600px) {
    
}

@media(min-width:320px) and (max-width:767px){
    .loverdiam_logo img {
        width: 150px !important;
    }
    .get_in {
        padding: 30px 15px !important;
    }
    .info_icons svg {
        width: 25px;
        height: 18px;
    }
    .info_icons span {
        padding-left: 10px;
        font-size: 14px;
    }
    .two_buttons {
        display: block;
    }
    button.settings {
        width: 100%;
        height: 45px;
        font-size: 14px;
    }
    .get_in button.settings.setborde.btn.btn-primary {
        margin-left: 0;
    }
    .get_in button.settings.setborde.btn.btn-primary {
        margin-left: 0;
    }
    .settings.setborde{
        margin-bottom: 0;
    }
   

}





@media(min-width:1200px) and (max-width:1440px){
    button.settings {
        width: 240px;
        font-size: 14px !important;
    }
    .two_buttons {
        display: flex;
        gap: 15px;
    }
    .get_in button.settings.setborde.btn.btn-primary {
        margin-left: 0;
    }
    .back_gry {
        height: 434px !important;
    }
    .get_in {
        padding: 59px 45px !important;
    }
    .info_icons li a {
        padding-bottom: 11px !important;
    }
}